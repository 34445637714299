<template lang="pug">
.advocacy-create-dialog(
  v-if="!isPending"
)
  CreateEditDialog(
    :title="viewModel.context === 'edit' ? 'Update Issue' : 'Add Issue'"
    :item="advocacy"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveAdvocacy"
    saveAction="Save"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      h5 Associated to Organization Types:
      v-divider.mb-5

      OrgTypeSelect.mb-5(
        v-model="clone.orgTypes"
        :viewModel="{ activator: 'select' }"
        @save="updateOrgIds"
        :context="viewModel.context === 'edit' ? 'update' : 'create'"
      )

      h5 Issue Info
      v-divider.mb-5

      v-text-field(
        outlined
        dense
        label="Name"
        v-model="clone.name"
        :rules="[rules.required]"
      )

      .d-flex.justify-content-between.gap-8
        v-text-field(
          outlined
          dense
          label="Bill Number"
          v-model="clone.billNumber"
        )

        AmountFormItem(
          label="Issue Budget"
          v-model="clone.budget"
          :rules="rules"
        )

      h5 Run Dates
      v-divider.mb-5

      .d-flex.justify-content-between.gap-8
        DatePickerDialog(
          inputClass="w-full md:w-1/2 min-w-0"
          label="Start Date"
          v-model="clone.startDate"
        )

        DatePickerDialog(
          inputClass="w-full md:w-1/2 min-w-0"
          label="End Date"
          v-model="clone.endDate"
        )
  </template>

  <script>
  import { computed } from '@vue/composition-api'
  import { useGet } from 'feathers-vuex'
  import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
  import AmountFormItem from '@/components/Amount.FormItem.vue'
  import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'
  import DatePickerDialog from '@/components/DatePicker.Dialog'

  export default {
    name: 'AdvocacyCreateEditDialog',

    components: {
      AmountFormItem,
      OrgTypeSelect,
      CreateEditDialog,
      DatePickerDialog
    },

    props: {
      title: {
        type: String,
        default: 'Add Admin'
      },

      viewModel: {
        type: Object,
        default: () => ({ context: 'create' })
      },

      isOpen: Boolean
    },

    setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError } }) {
      const { context, id } = props.viewModel
      const { Advocacy, AdvocacyOrganizationType } = $FeathersVuex.api
      const { value: { item: advocacy, isPending } } = computed(() => {
        return context === 'edit'
          ? useGet({
              model: Advocacy,
              id,
              params: {
                query: {
                  $eager: 'orgTypes'
                }
              }
            })
          : { item: new Advocacy({}), isPending: false }
      })

      const updateOrgIds = async (ids) => {
      try {
        const advocacyId = parseInt(id)
        const advocacyOrgTypes = new AdvocacyOrganizationType({
          advocacyId,
          organizationTypeId: ids
        })
        await advocacyOrgTypes.create()

        $snackSuccess('Org types updated')
      } catch (e) {
        console.error(e)
      }
    }

      const saveAdvocacy = async ({ clone, save, reset }) => {
        try {
          if (props.viewModel.context === 'create')
            clone.organizationTypeIds = clone.orgTypes

          await save()
          $snackSuccess(props.viewModel.context === 'create' ? 'Issue created' : 'Issue Updated')
        } catch (e) {
          reset()
          $snackError('Error adding an issue')
        } finally {
          emit('close')
        }
      }

      return {
        advocacy,
        isPending,
        updateOrgIds,
        saveAdvocacy
      }
    }
  }
  </script>

  <style>
  </style>