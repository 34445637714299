<template lang="pug">
.org-type-select
  v-dialog(
    max-width="700"
    persistent
    v-model="isOpen"
  )
    template( v-slot:activator="{ on, attrs }" )
      div( v-if="viewModel.activator === 'button'" )
        v-btn(
          v-on="on"
          color="primary"
          small
          depressed
          class="opacity-70 hover:opacity-100"
        )
          | update
      div( v-on="on" v-if="viewModel.activator === 'select'" )
        v-select(
          readonly
          v-on="on"
          v-bind="attrs"
          outlined
          hide-details
          dense
          label="Org Types"
          :items="viewOrgTypes.data"
          v-model="selectedOrgTypes",
          item-text="name"
          item-value="id"
          multiple
        )
          template( v-slot:selection="{ item, index }" )
            div( :class="{ 'ellipsis': singleSelect }" )
              span( class="inline text-base text-gray-500" v-if="index === 0 && !singleSelect" )
                |&nbsp;({{ selectedOrgTypes.length }} selected)
              span( class="inline text-base text-gray-500" v-if="singleSelect" )
                | {{ item.name }}

    v-card.px-0.mx-0
      v-card-title.px-6.bg-white.border-b.justify-between
        div Select Org Types
        v-spacer
        div
          v-btn(
            fab
            small
            depressed
            @click="isOpen = false"
          )
            v-icon mdi-close

      v-card-text.px-0.pb-0
        .pa-6.pb-4
          v-text-field(
            v-model="search"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            outlined
            dense
            hide-details
            clearable
          )

        v-data-table.cursor-pointer.border-t(
          v-model="selectedOrgTypes"
          :show-select="!singleSelect"
          must-sort
          single-select="singleSelect"
          @click:row="select"
          hide-default-footer
          :items="orgTypes"
          :loading="isPending"
          :headers="headers"
          :options.sync="options"
          no-data-text="No org types found"
          :single-select="singleSelect"
        )

      v-card-actions.px-6( right v-if="!singleSelect" ).bg-gray-100
        .d-flex.justify-space-between.w-full.bg-gray-100
          v-btn(
            depressed
            @click="isOpen = false"
          ) Cancel
          v-btn(
            depressed
            color="primary"
            type="submit"
            @click="save"
          ) {{ context === 'create' ? 'Select' : 'Save' }}

</template>

<script>
import { useFind } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'

export default {
  name: 'OrgTypeSelect',

  props: {
    value: {
      type: [Array, Number],
      require: true
    },
    context: {
      type: String,
      required: false,
      default: 'create'
    },
    viewModel: {
      type: Object,
      required: false,
      default: function () {
        return {
          activator: this.context === 'edit'
            ? 'button' : 'select'
        }
      }
    },
    singleSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props, { emit, root: { $FeathersVuex } }) {
    const propIds = Array.isArray(props.value)
      ? props.value
      : props.value ? [props.value] : []
    const selectedOrgTypes = ref(propIds)
    const { OrganizationType } = $FeathersVuex.api
    const isOpen = ref(false)

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'Name',
          value: 'name',
          filterable: true
        },
        {
          text: 'State',
          value: 'state.short',
          align: 'center',
          sortable: false
        }
      ],
      [],
      false
    );

    const viewOrgTypes = computed(() => {
      const orgTypes = OrganizationType.findInStore({ query: {} })

      if (orgTypes.data.length === 0) {
        useFind({
          model: OrganizationType,
          params: { query: {} }
        })
      }

      return orgTypes
    })

    const irsDesignation = ref([1,2,3,4,5]);
    const filter = computed(() => {
      return {
        $eager: '[irsDesignation, state]',
        irsDesignationId: { $in: irsDesignation.value }
      }
    });
    const {
      items: orgTypes,
      isPending,
      options,
      search
    } = useDataTableFind(
      'organization-types',
      filterableColumns,
      filter,
      { sortBy: ['name'], itemsPerPage: 999 }
    )

    const select = (orgType, row) => {
      if (props.singleSelect) {
        selectedOrgTypes.value = [orgType]
        save()
      } else {
        row.select(!row.isSelected)
      }
    }

    const save = () => {
      const orgTypes = selectedOrgTypes.value
      const ids = orgTypes.map(ot => ot.id)

      if (props.context === 'update')
        emit('save', ids)

      emit('input', ids)
      emit('saved', ids)
      isOpen.value = false
    }

    return {
      selectedOrgTypes,
      viewOrgTypes,
      isOpen,
      headers,
      search,
      options,

      orgTypes,
      isPending,

      save,
      select
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
  max-height: 450px;
  overflow-y: scroll !important;
}

::v-deep .v-data-table-header th {
  background-color: #f3f4f6;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;
}
</style>